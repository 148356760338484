import { useAuth, useAuthState, useMainStore, useMixpanel } from '#imports'
import * as Sentry from '@sentry/nuxt'

function resetUserIdentity() {
  const mainStore = useMainStore()
  const mixpanel = useMixpanel()

  mixpanel.reset()
  mainStore.$reset()
  Sentry.setUser(null)
}

function setUserIdentity(email: string) {
  resetUserIdentity()
  const mixpanel = useMixpanel()
  mixpanel.identify(email)
  Sentry.setUser({ email: email })
}

export async function authenticateWithToken(email: string, token: string) {
  const { setToken } = useAuthState()
  const { getSession } = useAuth()

  setToken(token)
  await getSession({ force: true })
  setUserIdentity(email)
}

export async function authenticateWithCredentials(email: string, password: string, callbackUrl?: string) {
  const { signIn } = useAuth()
  const options = callbackUrl ? { callbackUrl } : undefined
  await signIn({ email, password }, options)
  setUserIdentity(email)
}

export async function signOut() {
  const auth = useAuth()
  await auth.signOut({ callbackUrl: '/login' })
  resetUserIdentity()
}
